export default function DiscordServer() {
    return (
        <a href="https://discord.gg/nyD92cE" target="_blank" rel="noreferrer">
            <img
                src="https://discord.com/api/guilds/316545691545501706/widget.png?style=shield"
                alt="osu!droid Discord Server"
            />
        </a>
    );
}
