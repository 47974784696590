import "../styles/main.css";

export default function Header() {
    return (
        <div className="top-banner">
            <h1>Elaina PP Project</h1>
            <p>A basic PP project for osu!droid.</p>
        </div>
    );
}
